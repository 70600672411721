<template>
    <b-form-group :disabled="disableStatus">
        <div>
            <b-form-radio v-model="select" name="time" :value="1">Send Immediately</b-form-radio>
        </div>
        <div class="mt-3">
          <b-row class="no-gutters">
            <div class="mt-2">
              <b-form-radio v-model="select" name="time" :value="2">Set Time To Send</b-form-radio>
            </div>
            <div v-if="select === 2" class="ml-3">
              <div class="input-container" >
                <datetime
                  type="datetime"
                  :class="v.form.send_time.$error ? 'date-picker border-error' : 'date-picker'"
                  :input-style="styleDatetime"
                  format="dd/MM/yyyy HH:mm:ss"
                  value-zone="local"
                  v-model="form.send_time"
                  :min-datetime="dateFormat()"
                  :disabled="disableStatus"
                  ref="transferSendTime"
                >
                </datetime>
                <div
                  class="icon-container text-right"
                  @click="$refs.transferSendTime.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'pointer color-primary'"
                    color="#B41BB4"
                  />
                </div>
                <div class="pd-left-30" v-if="v.form.send_time.$error">
                    <span class="text-error">Please input time.</span>
                </div>
              </div>
            </div>
          </b-row>
        </div>
    </b-form-group>
</template>

<script>
export default {
    props:{
        form:{
            required:true,
            type:Object
        },
        v:{
            required:true,
            type:Object
        },
        selected:{
            required:true,
            type:Number
        },
        disableStatus:{
            required:false,
            type:Boolean
        }
    },
    watch:{
        select(val){
            this.$emit('setTime',val);
        }
    },
    data(){
        return{
            select : this.selected,
            styleDatetime: {
              width: "100%",
              border: "none",
            },
        }
    },
    methods:{
        dateFormat(){
            let today = new Date();
            return this.$moment(today).format();
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .vdatetime.date-picker{
    display: inline-block;
}
.border-error{
    border-color: red;
    border: 1px solid red;
    border-radius: 5px;
}
::v-deep .date-picker .vdatetime-input{
    margin-bottom: 0;
}
.pd-left-30{
    padding-left: 30%;
}
.input-container {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
</style>
