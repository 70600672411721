<template>
  <div>
    <b-row>
      <b-col md="6">
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          v-model="form.name"
          :v="v.form.name"
          :isValidate="v.form.name.$error"
          :disabled="disableStatus"
        />
      </b-col>
    </b-row>
    <div>
      <div>
        <label class="type-name"
          >Segmentation Type <span class="text-error">*</span></label
        >
        <b-form-group>
          <b-form-radio-group
            v-model="form.segmentationType"
            :disabled="disableStatus"
          >
        <b-row>
          <b-col
            ><b-form-radio :value="1"
              >General (Sand Normal Message)</b-form-radio
            ></b-col
          >
          <b-col><b-form-radio :value="2">Campaign</b-form-radio></b-col>
        </b-row>
        <b-row>
            <b-col md="6">
                <InputText
                    textFloat="Name"
                    placeholder="Name"
                    type="text"
                    name="name"
                    isRequired
                    v-model="form.name"
                    :v="v.form.name"
                    :isValidate="v.form.name.$error"
                    :disabled="disableStatus"
                />
            </b-col>
            </b-row>
            <div>
              <label class="type-name mb-2">Segmentation Type<span class="text-error">*</span></label>
              <b-form-group>
                  <b-form-radio-group v-model="form.segmentationType" :disabled="disableStatus">
                      <b-row>
                          <b-col><b-form-radio :value="1">General (Sand Normal Message)</b-form-radio></b-col>
                          <b-col><b-form-radio :value="2">Campaign</b-form-radio></b-col>
                      </b-row>
                  </b-form-radio-group>
              </b-form-group>
              <div v-if="v.form.segmentationType.$error" class="text-error">
                  Please select type.
              </div>
            </div>
            <b-row v-if="form.segmentationType === 2">
              <b-col md="6">
                  <label class="label">Campaign <span class="text-error">*</span></label>
                  <b-form-select v-model="form.campaign_id" :class="v.form.campaign_id.$error ? 'border-error' : ''">
                      <template #first>
                        <b-form-select-option :value="null" disabled>Please Select</b-form-select-option>
                      </template>
                      <option
                          v-for="(campagin,c) in campaignList"
                          :key="c"
                          :value="campagin.id"
                      >
                          {{campagin.name}}
                      </option>
                  </b-form-select>
                  <div v-if="v.form.campaign_id.$error">
                      <span class="text-error">Please Select Campagin.</span>
                  </div>
              </b-col>
            </b-row>
          </b-form-radio-group>
        </b-form-group>
        <div v-if="v.form.segmentationType.$error" class="text-error">
          Please select type.
        </div>
      </div>
      <b-row v-if="form.segmentationType === 2">
        <b-col md="6">
          <label class="label">Campaign<span class="text-error">*</span></label>
          <b-form-select
            v-model="form.campaign_id"
            :class="v.form.campaign_id.$error ? 'border-error' : ''"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >Please Select</b-form-select-option
              >
            </template>
            <option
              v-for="(campagin, c) in campaignList"
              :key="c"
              :value="campagin.id"
            >
              {{ campagin.name }}
            </option>
          </b-form-select>
          <div v-if="v.form.campaign_id.$error">
            <span class="text-error">Please Select Campagin.</span>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    InputText,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    campaignList: {
      required: true,
      type: Array,
    },
    disableStatus: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      options: [
        { text: "General", value: 1 },
        { text: "Campaign", value: 2 },
      ],
      selected: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.type-name {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.text-error {
  color: red;
}
</style>
